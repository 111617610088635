<template>
  <div id="community" ref="community">
    <navigation-bar @search="searchCommunity" class="nav"  ref="topInput">
      <template #nleft>
        <div class="leftimg" @click="jumpPerson">
         <van-image
            round
            cover
            fit="cover"
            :src="$store.state.profile.headImg ? $store.state.profile.headImg :'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'"
          />
        </div>
      </template>
      <template #nright>
        <div class="rightImg" @click="popupShow">
          <img
              src="../assets/image/addpost.png"
              alt=""
          />
        </div>
      </template>
    </navigation-bar>
    
    <van-tabs v-model="active"
              class="tabs"
              swipeable
              animated
              title-active-color="#4587FF"
              color="#4587FF"
              line-width="25px"
              swipe-threshold="4"
              @change="onTabChange" 
    >
      <van-tab v-for="item in tabs"
               :key="item.id"
               :style="{minHeight: '100vh',overflow:'hidden'}"
               :title="item.title">
        
        <div class="empty" v-if="dataList.length == 0">
           <div class="empty-content">
            <img src="../assets/image/shopingcar-kong.png" alt="">
            <p>暂无内容</p>
          </div>
        </div>
      
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            v-else
        >
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <post-list2 v-for="(item,index) in dataList"
                     :key="index"
                     @jumpDetail="jumpDetail(item)"
                      @showOperation="showOperation(item,index)"
                      @handleshare="handleshare(item)"
                     :item="item"/>
        </van-pull-refresh>
        </van-list>

      </van-tab>
    </van-tabs>

    <van-popup v-model="showPopup" round position="bottom" >
      <div class="popup_item" @click="addPost(1)">
        <img src="../assets/image/post-type-1.png" alt=""/>
        <span>帖子</span>
      </div>
      <div class="popup_item" @click="addPost(2)">
        <img src="../assets/image/post-type-2.png" alt=""/>
        <span>功能BUG</span>
      </div>
      <div class="popup_item" @click="addPost(3)">
        <img src="../assets/image/post-type-3.png" alt=""/>
        <span>投诉建议</span>
      </div>
    </van-popup>

    <van-popup :class="dataItem.postType !==3 ?'popup-operation':'popup_active'" v-if="isshow!=true"  v-model="operation" position="bottom">
            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postCollect(dataItem,dataIndex)"  v-if="dataItem.collect !== true" v-show="dataItem.postType !==3">
              <img src="../assets/image/post-collect.png" alt=""/>
              <span>收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="removeCollect(dataItem,dataIndex)"  v-else v-show="dataItem.postType !==3">
              <img src="../assets/image/post-collect.png" alt=""/>
              <span>取消收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postShield(dataItem,dataIndex)">
              <img src="../assets/image/post-shield.png" alt=""/>
              <span>屏蔽</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postReport(dataItem)">
              <img src="../assets/image/post-report.png" alt=""/>
              <!-- 投诉 -->
              <span>举报</span>
            </div>
    </van-popup>

    <van-popup :class="dataItem.postType !==3 || isshow != true ?'popup-operation':'popup_active'" v-else v-model="operation" position="bottom" >
           <div :class="dataItem.postType !==3 || isshow != true ?'popup_item':''" @click="postCollect(dataItem,dataIndex)"  v-if="dataItem.collect != true" v-show="dataItem.postType !==3">
              <img src="../assets/image/post-collect.png" alt=""/>
              <span>收藏</span>
            </div>

            <div :class="dataItem.postType !==3 || isshow !== true ?'popup_item':'active_item'" @click="removeCollect(dataItem,dataIndex)"  v-else v-show="dataItem.postType !==3">
              <img src="../assets/image/post-collect.png" alt=""/>
              <span>取消收藏</span>
            </div>

            <div :class="dataItem.postType !==3 || isshow !== true ?'popup_item':'active_item'" @click="editor(dataItem)">
              <img src="../assets/image/My-bianji.png" alt=""/>
              <span>编辑</span>
            </div>
            <div :class="dataItem.postType !==3 || isshow !== true ?'popup_item':'active_item'" @click="delReport(dataItem,dataIndex)">
              <img src="../assets/image/My-del.png" alt=""/>
              <span>删除</span>
            </div>
    </van-popup>


  </div>
</template>

<script>
import NavigationBar from "./common/NavigationBar";
import PostList2 from "./community/PostList2";
import {findContent,Collection,removeCollect} from "../api/community";
import { delArticle,delBugOrSuggest} from '../api/user';
import { Dialog } from 'vant';
import qs from "qs"

export default {
  name: "Community2",
  data() {
    return {
      // 选中tab下标
      active: 0,
      loading: true,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      tabs: [
        {
          title: '全部',
          type: 0
        },
        {
          title: '活动',
          type: 1
        },
        {
          title: '帖子',
          type: 2
        },
        {
          title: '功能BUG',
          type: 3
        },
        {
          title: '投诉建议',
          type: 4
        },
      ],
      isLoading: false,
      dataList: [],
      dataItem:'',
      //发布内容
      showPopup: false,
      operation:false,
      type:1,
      isshow:false,
      userInfo:"",
      dataIndex:''
    }
  },
  components: {
    NavigationBar,
    PostList2
  },
  mounted(){
     window.addEventListener('scroll',this.handleScroll)
  },
  
  destroyed(){
     window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
     handleScroll(){
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      let scroll = scrollTop - this.scrollNum
      this.scrollNum = scrollTop
      if(scroll<0){
        this.$refs.topInput.slideBlur()
      }else if(scroll>0){
        this.$refs.topInput.slideBlur()
      }else{

      }
    },
        //编辑
          editor(item){
             sessionStorage.setItem("pageType","1")
             this.$router.push(`/addposts2/${item.postType}?id=${item.id}&tabType=${this.active}`)
          },
        
        jumpDetail(item){
            this.$router.push({
              name:'CommunitDetails',
              query:{
                id:item.id,
                type:item.postType,
                tabType:this.active,
                pageType:1,
              }
            })
        },
        //下拉刷新
        onRefresh(){
           setTimeout(() => {
              this.onTabChange()
              this.isLoading = false;
            }, 1000);
        },
        async del(item,index){
           const info = {
                dismainId:item.id
           } 
          const res = await delArticle(info)
          if(res.code === 0 ){
              this.$toast("删除成功！")
              // this.dataList.splice(index,1)
              // this.$set(this.dataList)
                this.$router.go(0)
          }    
        },
        async delbug(item,index){
            const info = {
               id : item.id
            }
            const res = await delBugOrSuggest(qs.stringify(info))
             if(res.code === 0 ){
              this.$toast("删除成功！")
              // this.dataList.splice(index,1)
              this.$router.go(0)
             }    
        },
         //删除
        delReport(item,index){
            this.operation = false
            Dialog.confirm({
              title: '删除',
              message: '是否删除该帖子吗？',
              confirmButtonText:'删除',
              confirmButtonColor:'#4588FF',
            })
              .then(() => {
                // on confirm
               if(item.postType == 1 || item.postType == 2){
                  this.del(item,index)
               }else{
                  this.delbug(item,index)
               }
              })
              .catch(() => {
                // on cancel 
              });
       },

    onLoad() {
      
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      /*if (this.active === 1 ){
        this.finished = true
        this.loading = false
        this.$toast.clear()
        return;
      }*/
      this.finished = false
      this.loading = true
      this.pageNum++
      this.findAllContent(this.tabs[this.active].type)
    },
    //跳转个人主页
    jumpPerson(){
      this.$router.push({
        name:'Userpersonal',
        query:{
           id:this.userInfo.userId
        }
      })
    },
    //分享
    handleshare(item){
      let txt = "http://"+window.location.host+"/#/CommunitDetails?id="+item.id+"&type="+item.postType
      if(window.android){
            const obj = {
              action:'share',
              data:{
                type:3,
                content:txt,
              }
          }
          window.android.postMessage(JSON.stringify(obj));
      }else if(window.webkit){
         // ios 环境下
        const obj = {
          action: "share",
          data: {
            type: 3,
            content:txt,
          },
        };
        let jsonStr = JSON.stringify(obj);
        window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonStr);
      }
    },
 
    showOperation(item,index){
       this.operation = true
       this.dataIndex = index
       this.dataItem = item
       let userInfo = JSON.parse(localStorage.getItem("userInfo"))
       if(item.userid == userInfo.userId){
         this.isshow = true
       }else{
         this.isshow = false
       }
    },
    searchCommunity(value){
      console.log(value)
        if(value == "" || value == undefined || value == null){
          this.$toast("请输入关键字！")
          return
        }
        sessionStorage.setItem("value",value)
        this.$router.push({
          name:'searchCommunity',
        })
    },
       //收藏
      async postCollect(item,index){
         let obj = ""
         obj = item
         const info = {
           collectType:1,
           targetId:obj.id,
           type:obj.postType,
         }
         const result = await Collection(qs.stringify(info))
         if(result.code === 0 ){
           this.$toast(result.msg)
           this.operation = false
           this.dataList[index].collect = true
         }
      },
      //取消收藏
      async removeCollect(item,index){
         const info = {
           targetId:item.id,
           type:item.postType
         }
         const result = await removeCollect(qs.stringify(info))
         if(result.code === 0){
           this.$toast("取消收藏!")
           this.operation = false
           this.dataList[index].collect = false
         }else{
           this.$toast(result.msg)
           this.operation = false
         }
      },

      postReport(item){
         this.$router.push({
           name:'complaint',
           query:{
              id:item.id,
              type:item.postType,
              tabType:this.active,
           }
         })
      },
     //屏蔽
      async postShield(item){
         let obj = ""
         obj = item
         const info = {
           collectType:2,
           targetId:obj.id,
           type:obj.postType,
         }
         const result = await Collection(qs.stringify(info))
         if(result.code === 0 ){
           this.$toast("屏蔽成功！")
           this.operation = false
           this.$router.go(0)
         }
      },
    onTabChange(){
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.$refs.topInput.slideBlur()
      this.dataList = []
      this.pageNum = 1
      this.loading = true
      this.finished = false
      /*if (this.active === 1 ){
        this.finished = true
        this.loading = false
        this.$toast.clear()
        return;
      }*/
      this.findAllContent(this.tabs[this.active].type)
    },
   
    async findAllContent(type) {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        praiseType: 0,
        type: type
      };
      const result = await findContent(params);
      if (result.code === 0) {
        this.$toast.clear()
        this.loading = false
        this.finished = false

        if (result.data.list.length === 0) {
          this.loading = false
          this.finished = true
        }

        if (result.data.isLastPage || result.data.pages <= this.pageNum) {
          this.finished = true
        }
        
        result.data.list.map(item=>{
           this.dataList.push(item)
           if(item.pics.length >0){
            item.pics =  item.pics.slice(0,4)
           }
        })


      }
    },
    popupShow(){
      this.showPopup = true;
    },
    addPost(type){
      this.$router.push(`/addposts2/${type}`)
    },
  },
  created() {
    if(this.$route.query.tabType){
      this.active = this.$route.query.tabType
      this.onTabChange()
      return
    }
    this.pageNum = 1
    this.findAllContent(0)
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
  },
}
</script>

<style scoped lang="scss">
.nav{
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 5;
}
.leftimg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: -5px;
  overflow: hidden;
}

.empty{
      display: flex;
      justify-content: center;
      align-items: center;
      height:70vh;
      .empty-content{
        width: 162px;
        height: 135px;
        text-align: center;
        img{
          width: 162px;
          height: 118px;
        }
        p{
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #999999
        }
      }
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rightImg{
  margin-left: 15px;
  width: 22px;
}

.tabs {
  margin-top: 50px;
  font-size: 15px;
  font-family: PingFang-SC-Medium;
  padding-top: 30px;
}
::v-deep .van-tabs__wrap{
    position: fixed;
    top: 35px;
    width: 100%;
    background: #fff;
    z-index: 99;
}

.van-popup{
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup-operation{
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.activePopup{
  height: 20%;
}
.popup_item{
  font-size: 14px;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
}
.popup_item > img{
  width: 20px;
  height: 20px;
  transform: translateY(-2px);
}
.popup_item > span{
  margin-left: 20px;
}
::v-deep .van-share-sheet__options{
  justify-content: center;
}
.popup_active{
  height:20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .active_item{
    font-size: 14px;
    margin-left: 20px;
    margin-top: 20px;
    flex: 1;
    img{
      width: 20px;
      height: 20px;
       transform: translateY(-2px);
    }
    span{
      margin-left: 20px;
    }
  }
}
::v-deep .van-image--round{
    width: 100%;
    height: 100%;
}
</style>